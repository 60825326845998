import React, {useEffect, useState} from 'react';
import FooterSection from '../../../components/FooterSection/Footer';
import DiscountDetailFirstSectionStoreDiscounts
    from "../../../components/AppSections/StoreDiscountsPageSections/DiscountDetailFirstSectionStoreDiscounts";
import SimilarStoreDiscounts from "../../../components/AppSections/StoreDiscountsPageSections/SimilarStoreDiscounts";
import {Redirect, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAllStoreDiscountsCall} from "../../../api/ApiCalls";
import {updateStoreDiscountsRS} from "../../../redux/reduxActions/reduxStoreActions";
import RedirectToAppModalComponent from "../../../components/AppComponents/RedirectToAppModalComponent";
import {appUrls} from "../../../config/Constants";

export default function StoreDiscountInfo() {
    const { city_name, name, id } = useParams();
    const dispatch = useDispatch();
    const storeDiscountsRS = useSelector(state => state.storeDiscountsRS);
    const [storeDiscount, setStoreDiscount] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [showRedirectAppModal, setShowRedirectAppModal] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getAllStoreDiscountsCall().then(data => {
            dispatch(updateStoreDiscountsRS(data));
            let element = data.find(item => parseInt(item.actionId) === parseInt(id));
            setStoreDiscount(element);
            setIsLoading(false);
        }).catch(error => {
            console.error('Error fetching discounts', error);
            setIsLoading(false);
        });
    }, [id, dispatch]);

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            setShowRedirectAppModal(true)
        }
    }, [id])


    if (isLoading) {
        return (
            <div className="text-center m-2">
                <div className="spinner-border text-knaek-secondary"></div>
            </div>
        );
    }

    return (
        <div className="homepage-4 pt-5 mt-5">
            <div className="main">
                <RedirectToAppModalComponent showModal={showRedirectAppModal} closeModal={()=> setShowRedirectAppModal(!showRedirectAppModal)} storeDiscount={storeDiscount}
                                             storeID={id} link={'knaek://Homepage?instore=true&id='} online={false} icon={appUrls.image_url + storeDiscount?.iconId} title={storeDiscount?.localization['nl']?.title}/>
                {storeDiscount && (
                    <>
                        <DiscountDetailFirstSectionStoreDiscounts storeDiscount={storeDiscount}/>
                        <SimilarStoreDiscounts storeDiscount={storeDiscount}/>
                    </>
                )}
                <FooterSection/>
            </div>
        </div>
    );
}
