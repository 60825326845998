import React from 'react';
import {appUrls} from "../../config/Constants";
import ImageComponent from "./ImageComponent";
import {useParams} from "react-router-dom";
import {hasAValue} from "../../uitls/SharedFunctions";
import {useSelector} from "react-redux";
import { useHandleLinkClick } from '../../hooks/useHandleLinkClick';

export default function FavoriteDiscountCard({item}) {
    const appLanguageRS = useSelector(state => state.appLanguageRS);
    let contentLanguage = appLanguageRS === "en"? "en": "nl"
    const handleLinkClick = useHandleLinkClick();

    let language = appLanguageRS
    const {city} = useParams();
    return (
        <div className={"card radius-15 border-0 store-discount-card cursor-pointer shadow"} onClick={() => goToInfo()}>
            <ImageComponent src={appUrls.image_url + item.imageId} className="homepage-online-section-online-card-background" alt=""/>

            <div className="card-body row store-discount-card-body">
                <div className={"col-12 d-flex justify-content-center"}>
                    <ImageComponent src={appUrls.image_url + item.iconId}
                                    className="bg-white radius-15 online-discount-card-logo shadow d-flex justify-content-center align-items-center"
                                    alt="" height={98} width={98}/>

                </div>
                <div className={"col-12 text-center"}>
                    <h6 className="w-100 mt-2 text-knaek-title d-block d-md-none">{item.localization[contentLanguage].subtitle.toString().substring(0, 25)}{item.localization[appLanguageRS].subtitle.toString().length > 25? "...": "" }</h6>
                    <h4 className="w-100 mt-2 text-knaek-title d-none d-md-block">{item.localization[contentLanguage].subtitle}</h4>
                    <h6 className="w-100 text-knaek-regular d-none d-md-block">{item.localization[contentLanguage].title}</h6>
                </div>
            </div>
        </div>

    );

    function goToInfo() {
        if (hasAValue(city)) {
            handleLinkClick("/studentenkorting/" + city + "/" + item.companyName + "/" + item.actionId)
        } else {
            handleLinkClick("/studentenkorting/" + item.cityNames[0] + "/" + item.companyName + "/" + item.actionId)
        }

    }
}



